import { Component } from '@angular/core';
import {
  ActivatedRoute,
  Data,
  NavigationEnd,
  Router,
  RouterEvent
} from '@angular/router';
import { IconsToRegister } from '@app/interfaces/icons-to-register';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { filter, map, mergeMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { UserService } from '@app/services/user/user.service';
import { NgxPermissionsService } from 'ngx-permissions';
import * as FullStory from '@fullstory/browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  /**
   * @description
   *
   * Window title without suffix
   */
  static readonly TITLE = 'Bitesize';

  /**
   * @description
   *
   * Window title with suffix
   *
   * @see TITLE
   */
  static readonly TITLE_SUFFIX = ` - ${AppComponent.TITLE}`;

  /**
   * List of icons to register
   */
  iconsToRegister: IconsToRegister = {
    '24dp': [
      'account_circle',
      'add',
      'add_photo_alternate',
      'apps',
      'arrow_back',
      'arrow_drop_down',
      'arrow_drop_up',
      'arrow_forward',
      'arrow_upward',
      'arrow_downward',
      'appointment_set',
      'arrow_right_alt',
      'attach_file',
      'attach_money',
      'autorenew',
      'bar_chart',
      'barcode_reader',
      'cancel',
      'car_tag',
      'check',
      'check_circle',
      'close',
      'clone',
      'cloud_done',
      'cloud_upload',
      'code',
      'contacts',
      'data_usage',
      'date_range',
      'delete',
      'done',
      'done_all',
      'drafts',
      'edit',
      'email',
      'emoji_emotions',
      'exit_to_app',
      'filter_list',
      'inbox',
      'info',
      'insert_drive_file',
      'integrations',
      'keyboard_arrow_down',
      'label',
      'link',
      'tags',
      'error',
      'flag',
      'forward',
      'gavel',
      'group',
      'group_add',
      'important',
      'import_export',
      'markunread',
      'markunread_mailbox',
      'menu',
      'message',
      'more_vert',
      'not_interested',
      'notes',
      'needs_response',
      'opt_out',
      'open_in_new',
      'outlined_flag',
      'outlined_response',
      'pause',
      'phone_call',
      'phone_callback',
      'phone_disabled',
      'play_arrow',
      'price_change',
      'pending_actions',
      'refresh',
      'reply',
      'response',
      'save_alt',
      'schedule',
      'search',
      'settings_applications',
      'sold_verified',
      'security',
      'send',
      'sell',
      'spellcheck',
      'star',
      'star_border',
      'text_format',
      'visibility',
      'visibility_off',
      'warning',
      'content_copy',
      'whatshot',
      'meeting_room'
    ],
    '18dp': ['pause_circle_filled-white', 'correct', 'cloud_download']
  };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private domSanitizer: DomSanitizer,
    private matIconRegistry: MatIconRegistry,
    private ngxPermissionsService: NgxPermissionsService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private titleService: Title
  ) {
    //Fullstory init
    FullStory.init({
      orgId: 'TFPXC',
      namespace: 'FS'
    });

    /**
     * Register 24dp icons
     */

    this.iconsToRegister['24dp'].forEach((iconName: string): void => {
      this.matIconRegistry.addSvgIcon(
        iconName,
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          `assets/icons/24dp/${iconName}.svg`
        )
      );
    });
    this.iconsToRegister['18dp'].forEach((iconName: string): void => {
      this.matIconRegistry.addSvgIcon(
        iconName,
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          `assets/icons/18dp/${iconName}.svg`
        )
      );
    });
    /**
     * Watch for page changes then update window title with
     */
    this.router.events
      .pipe(
        filter(
          (event): event is NavigationEnd => event instanceof NavigationEnd
        ),
        map((): ActivatedRoute => this.route),
        map((activatedRoute: ActivatedRoute): ActivatedRoute => {
          while (activatedRoute.firstChild) {
            activatedRoute = activatedRoute.firstChild;
          }
          return activatedRoute;
        }),
        filter(
          (activatedRoute: ActivatedRoute): boolean =>
            activatedRoute.outlet === 'primary'
        ),
        mergeMap(
          (activatedRoute: ActivatedRoute): Observable<Data> =>
            activatedRoute.data
        )
      )
      .subscribe((event: Data): void => {
        if (event['title']) {
          this.titleService.setTitle(
            `${event['title']}${AppComponent.TITLE_SUFFIX}`
          );
        } else {
          this.titleService.setTitle(AppComponent.TITLE);
        }
        /**
         * Send an page view event
         */
        this.googleAnalyticsService.pageView(event['urlAfterRedirects']);
      });
    UserService.userObservable.subscribe((): void => {
      this.ngxPermissionsService.loadPermissions(UserService.permissions);
    });
  }
}
